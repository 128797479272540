// TODO REMOVE IN FAVOUR OF BrowserCookieStorage.get
const getWindowCookieValue = (
  requests: {
    key: string;
    window: Window;
    parseJson?: boolean;
  }[],
) => {
  const cookie = window.document.cookie;
  if (!cookie) return null;
  const cookies = cookie.split(";");
  const response: Record<string, string | Record<string, unknown>> = {};
  for (const c of cookies) {
    const [name, value] = c.trim().split("=");
    const request = requests.find((r) => r.key === name);
    if (value && request) {
      try {
        const decodeURIComponentValue = decodeURIComponent(value);
        response[request.key] = request.parseJson
          ? JSON.parse(decodeURIComponentValue)
          : decodeURIComponentValue;
      } catch {
        response[request.key] = value;
      }
    }
  }
  return response;
};

export { getWindowCookieValue };
