import { printVersion } from "@/shared/index.js";
printVersion();

export { CookieStorage } from "@/shared/lib/storage.js";
export type {
  SessionStorage,
  CookieStorageSettings,
} from "@/shared/lib/storage.js";
export type { AuthConfig } from "@/server/config.js";
export { CivicAuth } from "@/server/session.js";
