import {
  type AuthStorage,
  type OAuthTokens,
  type User,
  type EmptyObject,
  type UnknownObject,
  type OIDCTokenResponseBody,
} from "@/types.js";
import type { AuthConfig } from "@/server/config.js";
import {
  getUser as getUserFromShared,
  getTokens as getTokensFromShared,
} from "@/shared/lib/session.js";
import { clearTokens as clearTokensUtil } from "@/shared/lib/util.js";
import { resolveOAuthAccessCode } from "@/server/login.js";
import { buildLoginUrl } from "@/server/login.js";
import { buildLogoutRedirectUrl } from "@/server/logout.js";
import { refreshTokens } from "@/server/refresh.js";
import { ServerAuthenticationResolver } from "@/server/ServerAuthenticationResolver.js";
import { DEFAULT_AUTH_SERVER } from "@/constants.js";
import type { AuthenticationResolver } from "@/services/types.js";

/**
 * CivicAuth is the main entry point for server-side authentication operations.
 * It provides a unified interface to all the authentication functions.
 */
export class CivicAuth {
  _authResolver: AuthenticationResolver | null = null;
  constructor(
    readonly storage: AuthStorage,
    readonly authConfig: AuthConfig,
  ) {}

  get oauthServer(): string {
    return this.authConfig.oauthServer || DEFAULT_AUTH_SERVER;
  }

  async getAuthResolver(): Promise<AuthenticationResolver> {
    if (this._authResolver) {
      return Promise.resolve(this._authResolver);
    }
    this._authResolver = await ServerAuthenticationResolver.build(
      {
        ...this.authConfig,
        oauthServer: this.oauthServer,
      },
      this.storage,
    );
    return this._authResolver;
  }
  /**
   * Gets the authenticated user with token validation
   * @returns The user object if authenticated, null otherwise
   */
  async getUser<
    T extends UnknownObject = EmptyObject,
  >(): Promise<User<T> | null> {
    const resolver = await this.getAuthResolver();

    try {
      // Validate the session before returning the user
      const session = await resolver.validateExistingSession();
      if (!session?.authenticated) {
        return null;
      }

      // If session is valid, use the shared implementation to get the user
      return getUserFromShared<T>(this.storage);
    } catch (error) {
      console.error("Token validation failed during getUser", error);
      return null;
    }
  }

  /**
   * Gets the authentication tokens with token validation
   * @returns The tokens if authenticated, null otherwise
   */
  async getTokens(): Promise<OAuthTokens | null> {
    const resolver = await this.getAuthResolver();

    try {
      // Validate the session before returning the tokens
      const session = await resolver.validateExistingSession();
      if (!session?.authenticated) {
        return null;
      }

      // If session is valid, use the shared implementation to get the tokens
      return getTokensFromShared(this.storage);
    } catch (error) {
      console.error("Token validation failed during getTokens", error);
      return null;
    }
  }

  /**
   * Resolve an OAuth access code to a set of OIDC tokens
   * @param code The access code from the query parameter
   * @param state The OAuth state parameter
   * @returns OIDC tokens
   */
  async resolveOAuthAccessCode(
    code: string,
    state: string,
  ): Promise<OIDCTokenResponseBody> {
    return resolveOAuthAccessCode(code, state, this.storage, this.authConfig);
  }

  /**
   * Check if the user is currently logged in
   * @returns true if logged in, false otherwise
   */
  async isLoggedIn(): Promise<boolean> {
    const resolver = await this.getAuthResolver();
    const session = await resolver.validateExistingSession();
    return session?.authenticated ?? false;
  }

  /**
   * Build a login URL to redirect the user to
   * @param options Additional options for building the login URL
   * @returns The login URL
   */
  async buildLoginUrl(options?: {
    scopes?: string[];
    state?: string;
    nonce?: string;
  }): Promise<URL> {
    return buildLoginUrl(
      {
        ...this.authConfig,
        scopes: options?.scopes,
        state: options?.state,
        nonce: options?.nonce,
      },
      this.storage,
    );
  }

  /**
   * Build a logout URL to redirect the user to
   * @param options Additional options for building the logout URL
   * @returns The logout URL
   */
  async buildLogoutRedirectUrl(options?: {
    scopes?: string[];
    state?: string;
  }): Promise<URL> {
    return buildLogoutRedirectUrl(
      {
        ...this.authConfig,
        scopes: options?.scopes,
        state: options?.state,
      },
      this.storage,
    );
  }

  /**
   * Refresh the current set of OIDC tokens
   * @returns The refreshed tokens
   */
  async refreshTokens(): Promise<OIDCTokenResponseBody> {
    return refreshTokens(this.storage, this.authConfig);
  }

  /**
   * Clear all authentication tokens from storage
   */
  async clearTokens(): Promise<void> {
    return clearTokensUtil(this.storage);
  }
}
