import { cookies } from "next/headers.js";
import {
  clearAuthServerSession,
  clearTokens,
  clearUser,
} from "@/shared/lib/util.js";
import type { KeySetter } from "@/shared/lib/types.js";
import { type CookieConfig } from "@/shared/lib/types.js";
import { CookieStorage } from "@/shared/lib/storage.js";

/**
 * Clears all authentication cookies on server. Note, this can only be called by the server
 */
const clearAuthCookies = async () => {
  const cookieStorage = new NextjsCookieStorage(); // no cookie storage needed to simply clear it
  await clearTokens(cookieStorage);
  await clearUser(cookieStorage);
  await clearAuthServerSession(cookieStorage);
};

class NextjsCookieStorage extends CookieStorage {
  constructor(public config: Partial<Record<KeySetter, CookieConfig>> = {}) {
    super({
      secure: true,
      httpOnly: true,
    });
  }

  async get(key: string): Promise<string | null> {
    const cookieStore = await cookies();
    return cookieStore.get(key)?.value || null;
  }

  async set(
    key: KeySetter,
    value: string,
    cookieConfigOverride: Partial<CookieConfig> = {},
  ): Promise<void> {
    const cookieStore = await cookies();
    const cookieSettings = this.config?.[key as KeySetter] || {
      ...this.settings,
    };
    const useCookieSettings = { ...cookieSettings, ...cookieConfigOverride };
    cookieStore.set(key, value, useCookieSettings);
  }

  async delete(key: KeySetter): Promise<void> {
    const cookieStore = await cookies();
    cookieStore.delete(key);
  }
}

export { clearAuthCookies, NextjsCookieStorage };
