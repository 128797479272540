import { useCivicAuthConfig } from "@/shared/hooks/useCivicAuthConfig.js";
import type { SessionData } from "@/types.js";
import { useEffect, useState } from "react";
import { BrowserCookieStorage } from "@/shared/index.js";
import { resolveAuthConfig } from "../config.js";
import { NextClientAuthenticationRefresher } from "../NextClientAuthenticationRefresher.js";

const useRefresh = (session: SessionData | null) => {
  const [error, setError] = useState<Error>();
  const authConfig = useCivicAuthConfig();

  // setup token autorefresh
  const [refresher, setRefresher] = useState<
    NextClientAuthenticationRefresher | undefined
  >(undefined);

  useEffect(() => {
    if (!authConfig) return;
    const abortController = new AbortController();
    const currentRefresher = refresher;
    const config = resolveAuthConfig(authConfig ?? {});
    const storage = new BrowserCookieStorage(
      config.cookies.tokens.access_token,
    );
    const onError = async (error: Error) => {
      console.error("Error refreshing token", error);
      refresher?.clearAutorefresh();
      setError(error);
    };
    NextClientAuthenticationRefresher.build(
      { ...authConfig },
      storage,
      onError,
    ).then((newRefresher) => {
      if (abortController.signal.aborted) return;

      currentRefresher?.clearAutorefresh();
      setRefresher(newRefresher);
    });

    return () => {
      abortController.abort();
      currentRefresher?.clearAutorefresh();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authConfig]); // Only depend on what actually changes

  useEffect(() => {
    if (session?.authenticated) {
      refresher?.setupAutorefresh();
    } else {
      refresher?.clearAutorefresh();
    }

    return () => refresher?.clearAutorefresh();
  }, [refresher, session?.authenticated]);

  return {
    error,
  };
};

export { useRefresh };
