import type { AuthConfig } from "@/server/config.js";
import type { AuthStorage } from "@/types.js";
import { DEFAULT_AUTH_SERVER, DEFAULT_SCOPES } from "@/constants.js";
import { GenericPublicClientPKCEProducer } from "@/services/PKCE.js";
import { GenericAuthenticationInitiator } from "@/services/AuthenticationService.js";
import { OAuthTokenTypes } from "@/shared/lib/types.js";

export async function buildLogoutRedirectUrl(
  config: Pick<AuthConfig, "clientId" | "postLogoutRedirectUrl"> &
    Partial<Pick<AuthConfig, "oauthServer">> & {
      scopes?: string[];
      state?: string;
    },
  storage: AuthStorage,
): Promise<URL> {
  // generate a random state if not provided
  const state = config.state ?? Math.random().toString(36).substring(2);
  const scopes = config.scopes ?? DEFAULT_SCOPES;
  const pkceProducer = new GenericPublicClientPKCEProducer(storage);
  const authInitiator = new GenericAuthenticationInitiator({
    ...config,
    state,
    scopes,
    oauthServer: config.oauthServer ?? DEFAULT_AUTH_SERVER,
    pkceConsumer: pkceProducer,
    redirectUrl: config.postLogoutRedirectUrl || "/",
  });

  const idToken = await storage.get(OAuthTokenTypes.ID_TOKEN);
  if (!idToken) throw new Error("No id_token found in storage");

  return authInitiator.signOut(idToken);
}
