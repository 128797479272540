"use client";
/**
 * A very small context provider for the user object - it takes the user object from the cookie and provides it to the app.
 */
import React, { useCallback, useEffect, useState } from "react";
import {
  resolveAuthConfig,
  type AuthConfigWithDefaults,
} from "@/nextjs/config.js";
import { resolveCallbackUrl } from "@/nextjs/utils.js";
import { ConfidentialClientPKCEConsumer } from "@/services/PKCE.js";
import { UserProvider } from "@/shared/providers/UserProvider.js";
import { useUserCookie } from "@/nextjs/hooks/useUserCookie.js";
import { CivicAuthConfigProvider } from "@/shared/providers/CivicAuthConfigContext.js";
import { SessionProvider } from "@/shared/providers/SessionProvider.js";
import { IframeProvider } from "@/shared/providers/IframeProvider.js";
import { TokenProvider } from "@/shared/providers/TokenProvider.js";
import { useSignIn } from "@/shared/hooks/useSignIn.js";
import { useCivicAuthConfig } from "@/shared/hooks/useCivicAuthConfig.js";
import { IFrameAndLoading } from "@/shared/components/IFrameAndLoading.js";
import { BlockDisplay } from "@/shared/components/BlockDisplay.js";
import { LoadingIcon } from "@/shared/components/LoadingIcon.js";
import { useIframe } from "@/shared/hooks/useIframe.js";
import type { AuthProviderProps } from "@/shared/providers/types.js";
import { useIsInIframe } from "@/shared/hooks/useIsInIframe.js";
import { AuthStatus, type UnknownObject, type User } from "@/types.js";
import { useRefresh } from "@/nextjs/hooks/useRefresh.js";
import { useCurrentUrl, useSession } from "@/shared/hooks/index.js";
import { BrowserCookieStorage } from "@/shared/index.js";
import { getIframeRef } from "@/shared/lib/iframeUtils.js";

type CivicNextAuthTokenProviderInternalProps<TUser extends UnknownObject> =
  NextCivicAuthProviderInternalProps & {
    isLoading: boolean;
    idToken?: string;
    user: User<TUser> | null;
    fetchUser: () => Promise<void>;
  };
type NextCivicAuthProviderInternalProps = Omit<
  AuthProviderProps,
  "clientId"
> & {
  resolvedConfig: AuthConfigWithDefaults;
};
type NextCivicAuthProviderProps = Omit<
  NextCivicAuthProviderInternalProps,
  "clientId" | "resolvedConfig" | "redirectUrl"
>;

const CivicNextAuthTokenProviderInternal = <
  TUser extends UnknownObject = UnknownObject,
>({
  children,
  isLoading,
  displayMode = "iframe",
  user,
  fetchUser,
  ...props
}: CivicNextAuthTokenProviderInternalProps<TUser>) => {
  const { iframeMode, resolvedConfig } = props;
  const { iframeRef, setIframeIsVisible, isIframeMounted, setIframeMounted } =
    useIframe();
  const civicAuthConfig = useCivicAuthConfig();
  const { challengeUrl } = resolvedConfig;
  const pkceConsumer = new ConfidentialClientPKCEConsumer(challengeUrl);
  const { data: session } = useSession();
  const currentUrl = useCurrentUrl();

  useEffect(() => {
    if (session?.authenticated) {
      setIframeMounted(false);
      // the session is authenticated, so don't show the login iframe
      setIframeIsVisible(false);
      return;
    }
  }, [session?.authenticated, setIframeIsVisible, setIframeMounted]);

  const postSignOut = useCallback(async () => {
    // user is signed out, manually update the user from cookies to not wait for polling
    await fetchUser();
    await props?.onSignOut?.();
  }, [fetchUser, props]);

  const { signIn, startSignIn, signOut, authStatus } = useSignIn({
    postSignOut,
    pkceConsumer,
    displayMode,
  });

  useEffect(() => {
    const ref = getIframeRef(iframeRef?.current, true);
    if (
      isIframeMounted &&
      civicAuthConfig &&
      !session?.authenticated &&
      ref &&
      authStatus === AuthStatus.UNAUTHENTICATED &&
      displayMode === "iframe" &&
      !currentUrl?.includes("code=")
    ) {
      startSignIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isIframeMounted,
    currentUrl,
    iframeMode,
    iframeRef,
    civicAuthConfig,
    session?.authenticated,
    authStatus,
    startSignIn,
    displayMode,
  ]);

  const { error: refreshError } = useRefresh(session);

  useEffect(() => {
    if (refreshError) {
      console.error("Error refreshing token, signing out...", refreshError);
      signOut();
    }
  }, [refreshError, signOut]);

  return (
    <TokenProvider>
      <UserProvider
        storage={new BrowserCookieStorage()}
        user={user}
        signOut={signOut}
        signIn={signIn}
        displayMode={displayMode}
        authStatus={authStatus}
      >
        <IFrameAndLoading error={null} isLoading={isLoading} />
        {isLoading && (
          <BlockDisplay>
            <LoadingIcon />
          </BlockDisplay>
        )}
        {children}
      </UserProvider>
    </TokenProvider>
  );
};

const CivicNextAuthProviderInternal = ({
  children,
  ...props
}: NextCivicAuthProviderInternalProps) => {
  // if the SDK loads in an iframe, we show the loading spinner as the iframe
  // will be waiting to be minimized
  const isLoading = useIsInIframe();
  const { user, idToken, fetchUser } = useUserCookie();

  const session = {
    authenticated: !!user,
    idToken,
  };

  return (
    <SessionProvider data={session} isLoading={isLoading}>
      <CivicNextAuthTokenProviderInternal
        {...props}
        user={user}
        idToken={idToken}
        fetchUser={fetchUser}
        isLoading={isLoading}
      >
        {children}
      </CivicNextAuthTokenProviderInternal>
    </SessionProvider>
  );
};

const CivicNextAuthProvider = ({
  children,
  ...props
}: NextCivicAuthProviderProps) => {
  const resolvedConfig = resolveAuthConfig();
  const {
    clientId,
    oauthServer,
    callbackUrl,
    challengeUrl,
    logoutUrl,
    refreshUrl,
    logoutCallbackUrl,
  } = resolvedConfig;
  const [redirectUrl, setRedirectUrl] = useState<string>("");

  useEffect(() => {
    if (typeof globalThis.window !== "undefined") {
      const appUrl = globalThis.window.location.origin;
      setRedirectUrl(resolveCallbackUrl(resolvedConfig, appUrl));
    }
  }, [callbackUrl, resolvedConfig]);

  return (
    <CivicAuthConfigProvider
      oauthServer={oauthServer}
      clientId={clientId}
      redirectUrl={redirectUrl}
      logoutRedirectUrl={logoutCallbackUrl}
      nonce={props?.nonce}
      challengeUrl={challengeUrl}
      refreshUrl={refreshUrl}
      logoutUrl={logoutUrl}
      logoutCallbackUrl={logoutCallbackUrl}
    >
      <IframeProvider iframeMode={props.iframeMode}>
        <CivicNextAuthProviderInternal
          {...props}
          resolvedConfig={resolvedConfig}
        >
          {children}
        </CivicNextAuthProviderInternal>
      </IframeProvider>
    </CivicAuthConfigProvider>
  );
};

export { CivicNextAuthProvider, type NextCivicAuthProviderProps };
