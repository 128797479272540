import type { AuthConfig } from "@/server/config.js";
import type { AuthStorage, OIDCTokenResponseBody } from "@/types.js";
import { BrowserAuthenticationRefresher } from "@/shared/lib/BrowserAuthenticationRefresher.js";

export class NextClientAuthenticationRefresher extends BrowserAuthenticationRefresher {
  static override async build(
    authConfig: AuthConfig,
    storage: AuthStorage,
    onError: (error: Error) => Promise<void>,
  ): Promise<NextClientAuthenticationRefresher> {
    const refresher = new NextClientAuthenticationRefresher(
      authConfig,
      storage,
      onError,
    );
    return refresher;
  }

  override async refreshAccessToken(): Promise<OIDCTokenResponseBody> {
    if (!this.authConfig) throw new Error("No auth config available");
    if (!this.authConfig.refreshUrl)
      throw new Error("No refresh URL available");

    try {
      const res = await fetch(`${this.authConfig?.refreshUrl}`);
      const json = await res.json();
      return json.tokens as OIDCTokenResponseBody;
    } catch (error) {
      console.error(
        "NextClientAuthenticationRefresher refreshAccessToken: Failed to refresh tokens:",
        error,
      );
      this.onError(error as Error);
      throw error;
    }
  }
}
