import { resolveAuthConfig } from "@/nextjs/config.js";
import { NextjsCookieStorage } from "@/nextjs/cookies.js";
import { ServerAuthenticationResolver } from "@/server/ServerAuthenticationResolver.js";
import type { AuthenticationResolver } from "@/services/types.js";
import { printVersion } from "@/shared/index.js";
printVersion();
import {
  getTokens as getSessionTokens,
  getUser as getSessionUser,
} from "@/shared/lib/session.js";
import type { EmptyObject, OAuthTokens, UnknownObject, User } from "@/types.js";
export { resolveAuthConfig };

export { createCivicAuthPlugin, defaultAuthConfig } from "@/nextjs/config.js";

const getAuthResolver = async (): Promise<AuthenticationResolver> => {
  const config = resolveAuthConfig();
  const clientStorage = new NextjsCookieStorage();
  return ServerAuthenticationResolver.build(
    { ...config, redirectUrl: config.callbackUrl },
    clientStorage,
  );
};

export const isLoggedIn = async (): Promise<boolean> => {
  const authResolver = await getAuthResolver();
  const existingSession = await authResolver.validateExistingSession();
  return existingSession.authenticated;
};

export const getUser = async <
  T extends UnknownObject = EmptyObject,
>(): Promise<User<T> | null> => {
  const hasValidSession = await isLoggedIn();
  if (!hasValidSession) return null;
  const clientStorage = new NextjsCookieStorage();
  return getSessionUser<T>(clientStorage);
};

export const getTokens = async (): Promise<OAuthTokens | null> => {
  const hasValidSession = await isLoggedIn();
  if (!hasValidSession) return null;
  const clientStorage = new NextjsCookieStorage();
  return getSessionTokens(clientStorage);
};

export { handler } from "@/nextjs/routeHandler.js";
export { NextjsCookieStorage } from "@/nextjs/cookies.js";
export type {
  AuthConfig,
  CookiesConfigObject,
  AuthConfigWithDefaults,
} from "@/nextjs/config.js";
export {
  CivicNextAuthProvider as CivicAuthProvider,
  type NextCivicAuthProviderProps as AuthProviderProps,
} from "@/nextjs/providers/NextAuthProvider.js";
