import { AuthenticationRefresherImpl } from "@/shared/lib/AuthenticationRefresherImpl.js";
import type { Endpoints, OIDCTokenResponseBody } from "@/types.js";
import type { AuthConfig } from "@/server/config.js";
import { storeServerTokens } from "@/shared/lib/util.js";
import type { CookieStorage } from "@/server/index.js";

export class NextServerAuthenticationRefresherImpl extends AuthenticationRefresherImpl {
  override storage: CookieStorage | undefined;
  constructor(
    authConfig: AuthConfig,
    storage: CookieStorage,
    onError: (error: Error) => Promise<void>,
    override endpointOverrides?: Partial<Endpoints>,
  ) {
    super(authConfig, storage, onError, endpointOverrides);
    this.storage = storage;
  }

  override async storeTokens(
    tokenResponseBody: OIDCTokenResponseBody,
  ): Promise<void> {
    if (!this.storage) throw new Error("No storage available");
    await storeServerTokens(this.storage, tokenResponseBody);
  }

  static override async build(
    authConfig: AuthConfig,
    storage: CookieStorage,
    onError: (error: Error) => Promise<void>,
    endpointOverrides?: Partial<Endpoints>,
  ): Promise<NextServerAuthenticationRefresherImpl> {
    const refresher = new NextServerAuthenticationRefresherImpl(
      authConfig,
      storage,
      onError,
      endpointOverrides,
    );
    await refresher.init();

    return refresher;
  }
}
