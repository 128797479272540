import type { AuthStorage, OIDCTokenResponseBody } from "@/types.js";
import { DEFAULT_AUTH_SERVER } from "@/constants.js";
import type { AuthConfig } from "@/server/config.ts";
import { AuthenticationRefresherImpl } from "@/shared/lib/AuthenticationRefresherImpl.js";

/**
 * Refresh the current set of OIDC tokens
 */
export async function refreshTokens(
  storage: AuthStorage,
  config: AuthConfig,
): Promise<OIDCTokenResponseBody> {
  const refresher = await AuthenticationRefresherImpl.build(
    {
      ...config,
      oauthServer: config.oauthServer ?? DEFAULT_AUTH_SERVER,
    },
    storage,
    (error) => {
      console.error("Error refreshing tokens", error);
      return Promise.reject(error);
    },
    config.endpointOverrides,
  );

  return refresher.refreshTokens();
}
